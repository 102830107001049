var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services"},[_c('div',{staticClass:"services__list"},[_c('div',{staticClass:"services__list--title"},[_vm._m(0),_c('h3',[_vm._v(_vm._s(_vm.$t(_vm.title)))])]),_c('div',{staticClass:"services__list--content"},_vm._l((_vm.services),function(service,i){return _c('div',{key:i,staticClass:"services__list--content-item",class:{
          'services__list--content-item_active':
            service.name == _vm.activeService.name,
        },on:{"click":function($event){return _vm.setService(service)}}},[_vm._m(1,true),_c('span',[_vm._v(_vm._s(_vm.$t(service.name)))])])}),0)]),_c('div',{staticClass:"services__details"},[(_vm.activeService.children)?_c('div',{staticClass:"services__details--items"},[_vm._l((_vm.activeService.children),function(card,i){return _c('div',{key:i,staticClass:"services__details--items-card"},[_c('div',{staticClass:"service-card__icon"},[_c('img',{attrs:{"src":card.icon}})]),_c('div',{staticClass:"service-card__content"},[_c('div',[_c('div',{staticClass:"service-card__content--title"},[_c('h4',[_vm._v(_vm._s(_vm.$t(card.title)))])]),_c('p',[_vm._v(_vm._s(_vm.$t(card.description)))])]),_c('button',{staticClass:"service-card__content--action",on:{"click":function($event){return _vm.$router.push(card.link)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("Vezi detalii")))]),_vm._m(2,true)])])])}),(_vm.activeService.see_more)?_c('button',{staticClass:"seeMore-btn"},[_vm._v(" "+_vm._s(_vm.$t("See More"))+" ")]):_vm._e()],2):(_vm.activeService.content)?_c('div',{staticClass:"services__details--info"},[_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t(_vm.activeService.content.title)))]),(_vm.activeService.content.description)?_c('p',[_vm._v(" "+_vm._s(_vm.$t(_vm.activeService.content.description))+" ")]):(_vm.activeService.content.list)?_vm._l((_vm.activeService.content.list),function(item,i){return _c('p',{key:i},[_vm._v(" "+_vm._s(_vm.$t(item))+" ")])}):_vm._e()],2),(_vm.link)?_c('button',{staticClass:"service-card__content--action",on:{"click":function($event){return _vm.$router.push(_vm.activeService.content.link)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("Vezi detalii")))]),_vm._m(3)]):_vm._e()]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services__list--title-icon"},[_c('img',{attrs:{"src":require("@/assets/icons/information.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-item__icon"},[_c('img',{attrs:{"src":require("@/assets/icons/right-arrow.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-card__content--action-icon"},[_c('img',{attrs:{"src":require("@/assets/icons/right-arrow.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-card__content--action-icon"},[_c('img',{attrs:{"src":require("@/assets/icons/right-arrow.png")}})])
}]

export { render, staticRenderFns }